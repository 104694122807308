import { useEffect, useState } from 'react'

// adding 'isHydrated' to the state is necessary to resolve the following errors:
// suspense boundary hydration error stemming from React 18 upgrade (Minified react error 423) (see https://avantinc.atlassian.net/browse/PROS-48)
// UI mismatch hydration error stemming from React 18 upgrade (Minified react error 418) (see https://avantinc.atlassian.net/browse/PROS-49)
function useIsHydrated() {
  const [isHydrated, setIsHydrated] = useState(false)

  useEffect(() => {
    setIsHydrated(true)

    return () => {
      setIsHydrated(false)
    }
  }, [])

  return isHydrated
}

export default useIsHydrated
