const integrationsMap = [
  [], // Functional
  ['Datadog', 'Heap'], // Analytics
  ['Google Analytics', 'Facebook Pixel', 'Google Ads (Gtag)'], // Advertising
  ['Optimizely', 'Segment', 'Cloudflare'] // Essential
]

export function setConsentManagerTools() {
  setTimeout(() => {
    const tools = document.querySelectorAll('tr td:nth-child(4)')
    tools.forEach(
      // eslint-disable-next-line no-param-reassign
      (g, position) => (g.innerHTML = integrationsMap[position].join(', '))
    )
  }, 1)
}
