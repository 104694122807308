import React from 'react'
import { loadPreferences } from '@segment/consent-manager'
import { LogInProvider } from './src/context/LogInContext'
import SegmentConsentManager from './src/components/SegmentConsentManager'
import initializeDatadog from './src/helpers/datadog'

export function onClientEntry() {
  const segmentTrackingPreferences = loadPreferences()
  if (segmentTrackingPreferences?.customPreferences?.marketingAndAnalytics === false) {
    window.removeCookie('_dd_s')
    window.datadogRum = null
  } else {
    initializeDatadog()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <LogInProvider>
      {element}
      <SegmentConsentManager />
    </LogInProvider>
  )
}
