import React from 'react'
import { ConsentManager, onPreferencesSaved } from '@segment/consent-manager'
import { getSegmentWriteKey } from '../helpers/segmentTracker'
import { getEnv } from '../helpers/environment'
import useIsHydrated from './Hooks/useIsHydrated'
import initializeDatadog from '../helpers/datadog'
import { setConsentManagerTools } from '../helpers/consentManagerTools'

export const preferencesCallback = preferences => {
  window.gtag('consent', 'update', {
    ad_storage: preferences.customPreferences.advertising ? 'granted' : 'denied',
    analytics_storage: preferences.customPreferences.marketingAndAnalytics ? 'granted' : 'denied',
    functionality_storage: preferences.customPreferences.functional ? 'granted' : 'denied',
    personalization_storage: preferences.customPreferences.advertising ? 'granted' : 'denied',
    security_storage: preferences.customPreferences.functional ? 'granted' : 'denied',
    wait_for_update: 2000 // milliseconds
  })
  if (preferences?.customPreferences?.marketingAndAnalytics === false) {
    window.removeCookie('_dd_s')
    window.datadogRum = null
  } else {
    initializeDatadog()
  }
}

function SegmentConsentManager() {
  const isHydrated = useIsHydrated()
  if (!isHydrated) return null
  const env = getEnv()
  const segmentWriteKey = getSegmentWriteKey(env)
  const bannerContent = (
    <span>
      This site uses cookies and related technologies, as described in our{' '}
      <a href="/privacy-policy/" target="_blank">
        General privacy policy
      </a>
      , for purposes that may include site operation, analytics, enhanced user experience, or
      cross-contextual behavioral advertising.
    </span>
  )
  const bannerSubContent = ''
  const preferencesDialogTitle = 'Website Data Collection Preferences'
  const preferencesDialogContent =
    "We use data collected by cookies and related technologies for purposes that may include site operation, analytics, enhanced user experience or cross-contextual behavior advertising. In most cases, your opt-out preference will be tracked via a cookie, which means your selection is limited to the specific device and browser you're using during this visit to our website. If you visit this website from a different device or browser, change your browser settings, or if you clear your cookies, you may need to opt out again."
  const cancelDialogTitle = 'Are you sure you want to cancel?'
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our General Privacy Policy.'
  const preferencesDialogTemplate = {
    categories: [
      {
        key: 'essential',
        name: 'Essential',
        description: 'We use browser cookies that are necessary for the site to work as intended.',
        example:
          'For example, we store your website data collection preferences so we can honor them if you return to our site. You can disable these cookies in your browser settings but if you do the site may not work as intended.'
      },
      {
        key: 'functional',
        name: 'Functional',
        description:
          'To monitor the performance of our site and to enhance your browsing experience.',
        example: ''
      },
      {
        key: 'marketing',
        name: 'Analytics',
        description:
          'To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.',
        example: ''
      },
      {
        key: 'advertising',
        name: 'Advertising',
        description:
          'To personalize and measure the effectiveness of advertising on our site and other websites.',
        example:
          'For example, we may serve you a personalized ad based on the pages you visited on our site.'
      }
    ]
  }
  const initialPreferences = {
    functional: true,
    advertising: true,
    marketingAndAnalytics: true
  }

  onPreferencesSaved(preferencesCallback)
  const bannerActionsFunction = ({ changePreferences }) => {
    return (
      <div className="banner-actions">
        <button
          type="submit"
          className="manage-cookies"
          onClick={() => {
            changePreferences()
            setConsentManagerTools()
          }}
        >
          Manage Cookies
        </button>
      </div>
    )
  }
  return (
    <div id="__segmentConsentManager">
      <ConsentManager
        writeKey={segmentWriteKey}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        bannerActionsBlock={bannerActionsFunction}
        bannerTextColor="#000"
        bannerBackgroundColor="#f9f9f9"
        preferencesDialogTemplate={preferencesDialogTemplate}
        initialPreferences={initialPreferences}
        closeBehavior="accept"
      />
    </div>
  )
}

export default SegmentConsentManager
