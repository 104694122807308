import { datadogRum } from '@datadog/browser-rum'

const initializeDatadog = () => {
  datadogRum.init({
    applicationId: '40cf03d5-2c21-4ec0-a058-ade91a4a8d7a',
    clientToken: 'pube9706ae80c978d94e00d47efe23a15a1',
    site: 'datadoghq.com',
    service: 'avant-redesign',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    enabled: true,
    // env: activeEnv
    env: process.env.GATSBY_BASE_URL,
    /* eslint consistent-return: off */
    beforeSend: event => {
      if (
        event?.type === 'resource' &&
        event?.resource?.type === 'xhr' &&
        event?.resource?.url?.includes('/whoami')
      ) {
        return false
      }
    }
  })
  window.datadogRum = datadogRum
}

export default initializeDatadog
