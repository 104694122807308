export async function trackingSubmit(eventData) {
  if (eventData.segmentEvent && window.analytics) {
    try {
      await window.analytics.track(eventData.segmentEvent, {
        product_type: eventData.productType,
        location: 'ContentfulFormV3',
        text: eventData.text,
        url: window.location.href,
        ...eventData.payload,
        request_response: eventData.requestResponse
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export async function identifySubmit(eventData) {
  if (eventData.segmentEvent && window.analytics) {
    try {
      const {
        formValues: { first_name, last_name, email, street, state, unit, city, zipcode }
      } = eventData
      await window.analytics.identify({
        firstName: first_name,
        lastName: last_name,
        email,
        email_subscription_state: 'opted in',
        address: {
          street,
          unit,
          city,
          state,
          postalCode: zipcode
        }
      })
    } catch (err) {
      console.error(err)
    }
  }
}

// this is use only on myAvantCard hard-coded form
export function trackingSubmitHardCodedForm(eventName, eventData) {
  window?.analytics?.track(eventName, {
    ...eventData.values,
    destination: eventData.destination,
    location: eventData.location,
    text: eventData.text,
    url: window.location.href,
    brand_type: eventData.brandType,
    product_type: eventData.productType,
    request_response: eventData.requestResponse
  })
}

// this is used in ContentfulFormV3
async function sendSegmentData(segmentEventData) {
  await identifySubmit(segmentEventData)
  await trackingSubmit(segmentEventData)
}

export async function trackingSubmitContentfulForm(apiResponse, segmentEventData, formName) {
  if (formName === 'ODSForm') {
    await sendSegmentData(segmentEventData)
  } else if (formName === 'ODS_SSN') {
    await trackingSubmit(segmentEventData)
  } else {
    await trackingSubmit(segmentEventData)
  }
}

export const getSegmentWriteKey = env => {
  const segmentWriteKeyOptions = {
    development: 'UhVxLVB1OobWl4GFAKSiJLj7ONHEv8bN',
    staging: 'UhVxLVB1OobWl4GFAKSiJLj7ONHEv8bN',
    production: 'ETjg6GHahIucthwiiM2SwmBKpL2FkYpf'
  }
  return segmentWriteKeyOptions[env]
}
